import {createRoot} from "react-dom/client";
import {asyncWithLDProvider} from "launchdarkly-react-client-sdk";
import {hasWindow} from "./cratos/utils/app";
import "./index.css";
import {App} from "./App";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: hasWindow()
      ? window?._env_?.REACT_APP_LD_CLIENT_ID
      : process.env.REACT_APP_LD_CLIENT_ID,
    options: {},
  });

  const root = createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  );
})();
